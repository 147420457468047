<!--
 * @Author: your name
 * @Date: 2021-09-17 09:39:48
 * @LastEditTime: 2021-09-26 16:51:53
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \zhfd-web-sale\src\views\other\advice.vue
-->
<template>
  <div>
    <div v-if="showToast" class="mycontainer suc-toast">
      <img src="@/assets/images/myFavors/susess.png" />
      <p>意见反馈成功！</p>
      <div class="suc-btn" @click="showToast=false">返回</div>
    </div>
    <div v-else v-loading="loading" class="mycontainer">
      <h3>意见反馈</h3>
      <el-input
        v-model="text"
        class="text"
        rows="10"
        type="textarea"
        placeholder="请输入500字内反馈内容"
        maxlength="500"
        show-word-limit
      />
      <div class="mybtn" @click="useComplaint">提交</div>
    </div>
  </div>
</template>

<script>
import { feedBack } from '@/api/help'
import { debounce } from '@/utils/debounce'
export default {
  data() {
    return {
      loading: false,
      showToast: false,
      text: ''
    }
  },
  methods: {
    useComplaint: debounce(function () {
      this.loading = true
      feedBack({ feedbackContent: this.text, feedbackChannel: '1' })
        .then((res) => {
          if (res.code === 200) {
            console.log('res', res)
            this.showToast = true
            this.loading = false
            this.text = ''
          }
        })
        .catch((err) => {
          this.loading = false
          console.log('err', err)
        })
    }, 1000)
  }
}
</script>

<style lang="less" scoped>
.mycontainer {
  background: #fff;
  width: 930px;
  margin-bottom: 40px;
  padding: 40px;
  h3 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 50px;
  }
  .text input.el-input__inner {
    border: none;
  }
  .mybtn {
    width: 160px;
    background: linear-gradient(106deg, #f7d9b7 0%, #eabf96 100%);
    border-radius: 2px;
    text-align: center;
    line-height: 48px;
    color: #b88141;
    font-size: 20px;
    margin-top: 40px;
    cursor: pointer;
  }

  /deep/ .el-textarea__inner {
    padding: 24px;
    font-size: 14px;
    line-height: 20px;

    &:focus,
    &:hover {
      border-color: #b88141;
    }
  }
}

.suc-toast {
  text-align: center;
  p {
    margin-top: 54px;
    margin-bottom: 40px;
    font-weight: bold;
    font-size: 18px;
    line-height: 16px;
    color: #25293d;
  }

  .suc-btn {
    display: inline-block;
    padding: 17px 127px;
    background: linear-gradient(109.88deg, #f7d9b7 -3.02%, #eabf96 98.27%);
    border-radius: 4px;
    font-size: 16px;
    line-height: 16px;
    color: #b88141;
    cursor: pointer;
  }
}
</style>